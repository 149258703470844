.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #ebfdff;

  .wrapper {
    border-radius: 8px;
    padding: 2rem;

    background: white;

    h1, h4 {
      font-weight: normal;
      margin: 0;
    }
    .spacer {
      height: 3rem;
    }
  }
}
