.organization-page {
  padding: 1em;
}
.organization-page .horizontal {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 8px;
}
.organization-page .horizontal .org-list .organization {
  border: 1px solid lightgray;
  padding: 1rem 2rem;
  border-radius: 4px;
  cursor: pointer;
  background: white;
}
.organization-page .horizontal .org-list .organization:hover {
  background-color: #eee;
}
.organization-page .horizontal .org-list .organization.selected {
  text-decoration: underline;
}
.organization-page .horizontal .org-list .organization h4 {
  margin: 0;
}
.organization-page .horizontal .org-details {
  flex-grow: 1;
  padding: 1rem;
  border: 1px solid lightgray;
  border-radius: 4px;
  background: white;
}

