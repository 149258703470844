
.ol-map-parent {
  position: relative;
  width: 100%;

  .ol-map-container {
    width: 100%;
  }

  .popout-wrapper {
    position: absolute;

    left: 20px;
    bottom: 20px;


    .popout-btn {
      display: inline-block;
      padding: 8px;
      border: 0 solid lightgrey;
      border-radius: 4px;
      text-decoration: none;
      font-size: 1em;

      width: 45px;

      cursor: pointer;

      img {
        width: 100%;
      }

      &:hover {
        background: #EBF2F8;
        border-color: #2e4e63;
        color: #2e4e63;
      }
    }

    .popout-settings {
      position: absolute;

      background: white;

      overflow-y: auto;

      display: flex;
      flex-direction: column;
      gap: 8px;

      left: 0;
      bottom: 38px;

      width: 240px;
      height: 320px;

      padding: 16px 8px;

      border-radius: 4px;
      box-shadow: 4px 8px 12px 3px rgba(0, 0, 0, 0.13);

      &.close {
        display: none;
      }
    }
  }

  .heatmap-legend {
    position: absolute;
    background: white;
    padding: 4px;

    display: flex;
    flex-direction: column;
    align-items: center;

    right: 16px;
    top: 8px;

    .legend-colors {
      background: linear-gradient(to right, #9FFF9F, #d9534f);

      height: 20px;
      width: 200px;
    }
    .legend-text {
      display: flex;
      justify-content: space-between;
      width: 200px;
    }
  }

  .legend {
    position: absolute;
    left: 8px;
    top: 16px;

    display: flex;
    flex-direction: column;
    gap: 8px;

    .group {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;

      padding: 8px;
      gap: 6px;
      border-radius: 4px;

      background: whitesmoke;
      border: 1px solid black;

      .images {
        display: flex;
        gap: 8px;
        justify-items: center;
        height: 32px;

        img {
          height: 100%;
        }
      }

      label {
        font-weight: bold;
      }

      label.at-risk {
        background: red;
        color: white;
      }

      label.for-inspection {
        background: orange;
      }

      label.no-risk {
        background: green;
        color: white;
      }
    }
  }
  &.big-map {
    position: absolute;
    top: 0;
    left: 0;

    height: 100vh;
    width: 100%;
    background: #cccc;

    z-index: 5;

    display: flex;
    justify-content: center;
    align-items: center;

    .ol-map-container {
      width: 90%;
      height: 90% !important;

      left: 50%;
      top: 50%;
    }
  }

  .hold-shift-to-zoom-popup {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    top: 0;
    left: 0;

    pointer-events: none;

    p {
      position: absolute;
      left: 50%;
      top: 50%;

      transform: translate(-50%, -50%);

      background: white;
      padding: 16px;
      border-radius: 4px;
      box-shadow: 4px 8px 12px 3px rgba(0, 0, 0, 0.13);

      z-index: 1;
    }

    //  make disappear after 3 seconds
    @keyframes fadeOut {
      0% {
        opacity: 1;
      }
      80% {
        opacity: 1;
      }

      100% {
        opacity: 0;
      }
    }
    animation: fadeOut 5s forwards;
  }
}
@media print {
  .popout-btn {
    display: none !important;
  }
  .heatmap-legend {
    -webkit-print-color-adjust: exact; /* For WebKit browsers */
    print-color-adjust: exact;
  }
}