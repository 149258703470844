.popup {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(84, 87, 96, 0.64);
  z-index: 3;
}
.popup .close-popup-cross-btn {
  position: absolute;
  left: 15px;
  top: 15px;
  cursor: pointer;
  background: var(--app-dark-blue);
  border-radius: 100%;
  padding: 8px;
}
.popup .close-popup-cross-btn:hover {
  background: var(--accordion-list);
}
.popup .popup-wrapper {
  width: 90%;
  margin: 0 auto;
  margin-top: 2% !important;
  max-height: calc(100% - 40px);
  overflow-y: auto;
  background: whitesmoke;
}

