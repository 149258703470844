.live-page {

  .image-container {
    width: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    gap: 8px;
    flex-wrap: wrap;

    .img-wrapper {
      width: calc(50% - 4px);
      min-width: 350px;
      flex-grow: 1;

      position: relative;

      img {
        width: 100%;
      }

      label {
        position: absolute;
        background: rgba(255, 255, 255, 0.8);
        bottom: 8px;
        right: 8px;

        padding: 4px 8px;

        border-radius: 4px;
      }
    }
  }

  main {
    margin: 0 auto;
    width: 100%;
    max-width: 800px;

    .timestamp {
      margin: 0 auto;
      text-align: center;
    }
  }

  .metrics {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    margin-bottom: 1em;
  }

  .metric {
    min-width: 100px;

    border: 1px solid lightgray;
    border-radius: 8px;

    margin: 8px;
    padding: 32px 16px;

    display: flex;
    flex-direction: column;
    align-items: center;

    > * {
      margin: 0;
    }

    .value {
      font-size: 2em;
      font-weight: bold;
    }
  }
  .vertical {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }
  .horizontal {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  }
}
