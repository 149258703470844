.private-route {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin: 0 auto;
}
.private-route .spacer {
  flex-grow: 1;
  flex-shrink: 1;
}

