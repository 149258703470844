.water-profile-page main {
  display: flex;
  width: 100%;
}
.water-profile-page .metrics {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 360px;
  cursor: pointer;
}
.water-profile-page .metrics .metric {
  padding: 16px;
  border: 1px solid lightgray;
}
.water-profile-page .metrics .metric h4 {
  margin: 0;
}
.water-profile-page .metrics .metric:hover {
  background: lightgray;
}
.water-profile-page .basic-form-popup {
  width: 300px;
  padding: 32px 64px;
}
.water-profile-page .basic-form-popup form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}
.water-profile-page .basic-form-popup form label {
  display: flex;
  flex-direction: column;
}
.water-profile-page .selected-metric {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0 8px;
}
.water-profile-page .normal-function {
  background: lightgray;
}
.water-profile-page .normal-function svg {
  fill: black;
  height: 100%;
}

