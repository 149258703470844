.annotation-manager {
  .ol-zoom-out, .ol-zoom-in {
    display: none;
  }
  .create-new-wrapper {
    h5 {
      margin: 0;
    }

    display: flex;
    flex-direction: column;
    gap: 8px;

    width: 250px;
    padding: 16px;
  }
  .btn-wrapper {
    display: flex;
  }
}
.edit-annotation-popup {
  width: 350px !important;

  padding: 2rem;
  main {
    display: flex;
    flex-direction: column;
    gap: 8px;

    width: 250px;
    margin: 0 auto;

    textarea {
      min-height: 100px;
    }

    label, button {
      width: 100%;
      input, textarea {
        width: 100%;
      }
    }

    h2 {
      margin-top: 0;
    }
  }
}

@media print {
  .annotation {
    page-break-inside: avoid;

    border-bottom: 1px solid black;
  }
}
