.sortable-table {
  width: 100%;
  overflow-y: auto;

  table {
    background: whitesmoke;
    border-collapse: collapse;
    width: 100%;
    border-top: 1px solid grey;
    padding-left: 2px;

    th {
      background: whitesmoke;
      position: sticky;
      top: 0;
      cursor: pointer;

      color: #363b3e;
      font-size: 16px;
      text-align: left;
      font-weight: 700 !important;

      border-bottom: 1px solid lightgrey;

      svg {
        width: 12px;
        display: none;
        margin-left: 2px;
      }

      svg.focus {
        display: inline-block;
      }

      svg.desc {
        transform: rotate(180deg);
      }
    }

    tbody {
      overflow-y: scroll;

      tr.selected {

      }

      tr:hover {
        background: lightgray;
      }

      td {
        padding: 3px;
      }
    }
  }
}
