.data-calculator {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
.data-calculator .horizontal {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 32px;
}
.data-calculator .real-time-entries {
  display: flex;
}

