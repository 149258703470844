body {
  background: whitesmoke;
}

.summary {
  width: 80%;
  max-width: calc(800px);
  margin: 0 auto;
  padding: 1.5rem 3rem;

  background: white;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(650px, 1fr));

  .summary-header {
    display: flex;
    justify-content: space-between;

    > * {
      margin: 0;
    }
  }

  .details {
    border-collapse: collapse;
    width: 100%;

    margin: 2rem 0;

    th {
      text-align: left;
    }
  }

  .graphs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .parameter-wrapper {
    margin: 32px 0;

    h2, h4 {
      margin: 0;
    }

    .horizontal {
      display: flex;

      gap: 16px;
    }
  }
}

@media print {
  .summary {
  }
  .session-header {
    display: none !important;
  }
  .header > .inline-menu {
    display: none !important;
  }
  .header {
    .header-title {
      flex-direction: row-reverse;

      .logo {
        display: none !important;
      }
    }

  }
  .summary {
    margin: 0 !important;
  }
  .summary-header {
    flex-wrap: wrap;

    h2 {
      font-size: 2em !important;
    }
  }
  .sortable-table, .table, tbody {
    page-break-inside: auto;
  }
  .map-video-wrapper, canvas {
    page-break-inside: avoid !important;
  }
}
