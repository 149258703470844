.session-page {
  height: calc(100vh - 70px);
}
.session-page .tab-wrapper {
  display: flex;
}
.session-page .tab-wrapper .tab {
  padding: 4px 8px;
  cursor: pointer;
  font-weight: 600;
  color: rgba(24, 155, 214, 0.7);
  background: white;
  font-size: 1.1em;
  position: relative;
}
.session-page .tab-wrapper .tab:not(:last-of-type)::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 1px;
  height: 100%;
  background: black;
}
.session-page .tab-wrapper .tab.selected {
  cursor: default;
  color: black;
  font-weight: 400;
}
.session-page .tab-wrapper .tab:hover:not(.selected) {
  text-decoration: underline;
}
.session-page .session-header {
  width: calc(100% - 32px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 4px;
}
.session-page .session-header h2, .session-page .session-header sub {
  margin: 0;
}
.session-page .session-header sub {
  opacity: 0.5;
}
.session-page .session-header .videos {
  display: flex;
  flex-direction: column;
}
.session-page .session-header .burger-menu-wrapper {
  position: relative;
}
.session-page .session-header .burger-menu-wrapper .drop-down-menu-button {
  height: 45px;
  cursor: pointer;
}
.session-page .session-header .burger-menu-wrapper .burger-menu {
  position: absolute;
  width: 250px;
  right: 0;
  z-index: 2;
  background: white;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px;
  border: 1px solid lightgrey;
}
.session-page main {
  height: calc(100% - 52px);
  display: flex;
}
.session-page main .left-side {
  flex: 6;
  max-width: calc(60% - 16px);
  height: 100%;
  display: flex;
  flex-direction: column;
}
.session-page main .map-video-wrapper {
  display: flex;
  gap: 16px;
  flex: 8;
}
.session-page main .map-video-wrapper .selected-point-images {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  max-height: 100%;
  overflow-y: auto;
}
.session-page main .map-options {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 8px 0;
}
.session-page main .map-options h4 {
  margin: 0;
}
.session-page main .map-options .grower {
  flex-grow: 1;
}
.session-page main .map {
  min-height: 30vh;
  width: auto;
}
.session-page main table {
  background: white;
}
.session-page main table tbody tr.selected {
  background: lightgrey;
  border-bottom: 1px solid red;
}
.session-page main table tbody tr .hovering {
  background: lightgrey;
}
.session-page .edit-session-popup {
  width: 350px !important;
  padding: 16px 32px;
}
.session-page .right-side {
  flex: 4;
  max-width: 40%;
}
.session-page .graph-wrapper {
  width: 100%;
}
.session-page .graph-wrapper .graphs {
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
}
.session-page .graph-wrapper .graphs .key-number-wrapper {
  display: flex;
  gap: 8px;
}
.session-page .graph-wrapper .graphs.small h2 {
  margin: 0;
  font-size: 1em;
}
.session-page .graph-wrapper .graphs h2 {
  width: 150px;
}
.session-page .graph-wrapper .graphs h2 span {
  font-size: 0.8em;
  opacity: 0.7;
  margin-left: 4px;
  text-decoration: underline;
}
.session-page .graph-wrapper .graphs h4 {
  margin: 0;
  width: 150px;
  font-size: 0.8em;
}
.session-page .grower {
  flex-grow: 1;
}

@media print {
  .dont-print {
    display: none;
  }
}

