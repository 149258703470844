.private-route {
  display: flex;
  flex-direction: column;

  height: 100%;
  width: 100%;
  //max-width: 1500px;


  margin: 0 auto;
  .spacer {
    flex-grow: 1;
    flex-shrink: 1;
  }

  .footer {

  }
}
