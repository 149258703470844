.header {
  width: calc(100% - 64px);
  height: 45px;
  display: flex;
  align-items: center;

  padding: 0 32px;

  background: #3f7fff;
  color: white;

  .header-title {
    margin: 0;
    cursor: pointer;

    height: 100%;
    max-width: 240px;
    padding: 16px;

    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;

    gap: 16px;

    img {
      height: 40%;
    }
    .logo-text {
      max-width: 240px;

    }
    .logo {
      margin-top: 8px;
      max-width: 240px;
    }
  }

  .burger-menu-icon {
    display: none;
    height: 32px;

    &.isopen {
      z-index: 2;
    }
  }

  .inline-menu {
    display: flex;
    gap: 16px;

    a {
      color: white;
    }
  }
}

.menu-overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);

  align-items: center;
  justify-content: center;

  z-index: 50;

  .mobile-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 32px;

    a {
      color: white;
      font-size: 24px;
    }
  }
}

@media screen and (max-width: 768px) {
  .header {
    .inline-menu {
      display: none;
    }

    .burger-menu-icon {
      display: block;
    }
  }
  .mobile-menu, .menu-overlay {
    display: flex;
  }
}
