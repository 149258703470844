.show-less-more-wrapper {
  display: flex;
  align-items: flex-end;
  width: 100%;
}
.show-less-more-wrapper div {
  flex: 1;
}
.show-less-more-wrapper .show-less-more {
  padding-right: 8px;
  cursor: pointer;
}

