.annotation-manager .ol-zoom-out, .annotation-manager .ol-zoom-in {
  display: none;
}
.annotation-manager .create-new-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 250px;
  padding: 16px;
}
.annotation-manager .create-new-wrapper h5 {
  margin: 0;
}
.annotation-manager .btn-wrapper {
  display: flex;
}

.edit-annotation-popup {
  width: 350px !important;
  padding: 2rem;
}
.edit-annotation-popup main {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 250px;
  margin: 0 auto;
}
.edit-annotation-popup main textarea {
  min-height: 100px;
}
.edit-annotation-popup main label, .edit-annotation-popup main button {
  width: 100%;
}
.edit-annotation-popup main label input, .edit-annotation-popup main label textarea, .edit-annotation-popup main button input, .edit-annotation-popup main button textarea {
  width: 100%;
}
.edit-annotation-popup main h2 {
  margin-top: 0;
}

@media print {
  .annotation {
    page-break-inside: avoid;
    border-bottom: 1px solid black;
  }
}

