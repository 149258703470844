.sortable-table {
  width: 100%;
  overflow-y: auto;
}
.sortable-table table {
  background: whitesmoke;
  border-collapse: collapse;
  width: 100%;
  border-top: 1px solid grey;
  padding-left: 2px;
}
.sortable-table table th {
  background: whitesmoke;
  position: sticky;
  top: 0;
  cursor: pointer;
  color: #363b3e;
  font-size: 16px;
  text-align: left;
  font-weight: 700 !important;
  border-bottom: 1px solid lightgrey;
}
.sortable-table table th svg {
  width: 12px;
  display: none;
  margin-left: 2px;
}
.sortable-table table th svg.focus {
  display: inline-block;
}
.sortable-table table th svg.desc {
  transform: rotate(180deg);
}
.sortable-table table tbody {
  overflow-y: scroll;
}
.sortable-table table tbody tr:hover {
  background: lightgray;
}
.sortable-table table tbody td {
  padding: 3px;
}

