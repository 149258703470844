.water-profile-page {

  main {
    display: flex;

    width: 100%;
  }

  .metrics {
    display: flex;
    flex-direction: column;
    gap: 12px;

    width: 360px;
    cursor: pointer;

    .metric {
      padding: 16px;
      border: 1px solid lightgray;

      h4 {
        margin: 0;
      }

      &:hover {
        background: lightgray;
      }
    }
  }

  .basic-form-popup {
    width: 300px;
    padding: 32px 64px;

    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 32px;

      label {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .selected-metric {
    display: flex;
    flex-direction: column;
    gap: 8px;

    margin: 0 8px;
  }

  .normal-function {
    background: lightgray;

    svg {
      fill: black;
      height: 100%;
    }
  }
}
