.session-page {
  height: calc(100vh - 70px);

  .tab-wrapper {
    display: flex;

    .tab {
      padding: 4px 8px;
      cursor: pointer;

      font-weight: 600;
      color: rgba(24, 155, 214, 0.7);

      background: white;

      font-size: 1.1em;

      position: relative;

      &:not(:last-of-type)::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 1px;
        height: 100%;
        background: black;
      }

      &.selected {
        cursor: default;
        color: black;
        font-weight: 400;
      }

      &:hover:not(.selected) {
        text-decoration: underline;
      }
    }
  }

  .session-header {
    width: calc(100% - 32px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;

    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 4px;

    h2, sub {
      margin: 0;
    }

    sub {
      opacity: 0.5;
    }

    .videos {
      display: flex;
      flex-direction: column;
    }

    .burger-menu-wrapper {
      position: relative;

      .drop-down-menu-button {
        height: 45px;
        cursor: pointer;
      }

      .burger-menu {
        position: absolute;

        width: 250px;
        right: 0;
        z-index: 2;

        background: white;

        display: flex;
        flex-direction: column;
        gap: 16px;

        padding: 32px;

        border: 1px solid lightgrey;
      }
    }
  }

  main {
    height: calc(100% - 52px);

    display: flex;

    .left-side {
      flex: 6;
      max-width: calc(60% - 16px);
      height: 100%;

      display: flex;
      flex-direction: column;
    }

    .map-video-wrapper {
      display: flex;
      gap: 16px;
      flex: 8;

      .selected-point-images {
        display: flex;
        flex-direction: column;
        max-width: 500px;
        max-height: 100%;

        overflow-y: auto;
      }
    }

    .map-options {
      display: flex;
      align-items: center;

      gap: 16px;
      padding: 8px 0;

      h4 {
        margin: 0;
      }

      .grower {
        flex-grow: 1;
      }
    }

    .map {
      min-height: 30vh;
      width: auto;
    }

    table {
      background: white;

      tbody {
        tr {
          &.selected {
            background: lightgrey;
            border-bottom: 1px solid red;
          }

          .hovering {
            background: lightgrey;
          }
        }
      }
    }
  }

  .edit-session-popup {
    width: 350px !important;
    padding: 16px 32px;
  }

  .right-side {
    flex: 4;
    max-width: calc(40%);
  }

  .graph-wrapper {
    width: 100%;

    .graphs {
      height: 100%;
      overflow-y: auto;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      align-content: flex-start;

      .key-number-wrapper {
        display: flex;
        gap: 8px;
      }

      &.small {
        h2 {
          margin: 0;
          font-size: 1em;
        }
      }

      h2 {
        width: 150px;

        span {
          font-size: 0.8em;
          opacity: 0.7;
          margin-left: 4px;
          text-decoration: underline;
        }
      }

      h4 {
        margin: 0;
        width: 150px;
        font-size: 0.8em;
      }
    }
  }

  .grower {
    flex-grow: 1;
  }
}
@media print {
  .dont-print {
    display: none;
  }
}