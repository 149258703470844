.live-page .image-container {
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  gap: 8px;
  flex-wrap: wrap;
}
.live-page .image-container .img-wrapper {
  width: calc(50% - 4px);
  min-width: 350px;
  flex-grow: 1;
  position: relative;
}
.live-page .image-container .img-wrapper img {
  width: 100%;
}
.live-page .image-container .img-wrapper label {
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  bottom: 8px;
  right: 8px;
  padding: 4px 8px;
  border-radius: 4px;
}
.live-page main {
  margin: 0 auto;
  width: 100%;
  max-width: 800px;
}
.live-page main .timestamp {
  margin: 0 auto;
  text-align: center;
}
.live-page .metrics {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  margin-bottom: 1em;
}
.live-page .metric {
  min-width: 100px;
  border: 1px solid lightgray;
  border-radius: 8px;
  margin: 8px;
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.live-page .metric > * {
  margin: 0;
}
.live-page .metric .value {
  font-size: 2em;
  font-weight: bold;
}
.live-page .vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
.live-page .horizontal {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

