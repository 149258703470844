.water-profile-overview {
  padding: 0 16px;

  .profiles {
    display: flex;
    flex-direction: column;
    gap: 12px;

    width: 360px;

    .profile {
      padding: 16px;
      border: 1px solid lightgray;

      h4 {
        margin: 0;
      }

      &:hover {
        background: lightgray;
      }
    }
  }

  .create-new-profile {
    display: flex;
    flex-direction: column;
    gap: 16px;

    padding: 32px;

    width: 250px;

    margin-top: 64px;

    border: 1px solid lightgray;
    border-radius: 4px;

    h4 {
      margin: 0;
    }
  }
}
