.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #ebfdff;
}
.login-page .wrapper {
  border-radius: 8px;
  padding: 2rem;
  background: white;
}
.login-page .wrapper h1, .login-page .wrapper h4 {
  font-weight: normal;
  margin: 0;
}
.login-page .wrapper .spacer {
  height: 3rem;
}

