.simple-button {
  border: none;
  border-radius: 4px;
  background-color: #189bd6;
  color: white;
  padding: 8px 16px;
  font-size: 12px;
  cursor: pointer;
}

.simple-button:hover {
  transition: background-color 0.2s;
  background: rgba(24, 155, 214, 0.7);
}

.simple-button.disabled {
  background: grey;
  border-color: grey;
  opacity: 0.6;
  cursor: not-allowed;
  pointer-events: none;
}

.simple-button.loading {
  cursor: progress;
}

.simple-button.big {
  width: 100%;
  font-size: 1.3em;
}

.simple-button.red {
  background: rgba(153, 6, 0, 0.87);
}

.simple-button.red:hover {
  background: rgba(153, 6, 0, 0.6);
  transition: background-color 0.2s;
}

.simple-button.white {
  background: rgba(218, 218, 218, 0.8666666667);
  color: black;
}

.simple-button.white:hover {
  background: rgba(237, 236, 236, 0.87);
  color: black;
  transition: background-color 0.2s;
  border: 1px solid lightgray;
  margin: -1px;
}

.simple-button.round {
  border-radius: 100%;
  width: 48px;
  height: 48px;
  padding: 0;
  font-size: 30px;
  line-height: 30px;
}

