.organization-page {
  padding: 1em;

  .horizontal {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 8px;

    .org-list {
      .organization {
        border: 1px solid lightgray;
        padding: 1rem 2rem;
        border-radius: 4px;

        cursor: pointer;

        background: white;

        &:hover {
          background-color: #eee;
        }

        &.selected {
          text-decoration: underline;
        }

        h4 {
          margin: 0;
        }
      }
    }

    .org-details {
      flex-grow: 1;
      padding: 1rem;
      border: 1px solid lightgray;
      border-radius: 4px;

      background: white;
    }
  }
}
