.water-profile-overview {
  padding: 0 16px;
}
.water-profile-overview .profiles {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 360px;
}
.water-profile-overview .profiles .profile {
  padding: 16px;
  border: 1px solid lightgray;
}
.water-profile-overview .profiles .profile h4 {
  margin: 0;
}
.water-profile-overview .profiles .profile:hover {
  background: lightgray;
}
.water-profile-overview .create-new-profile {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px;
  width: 250px;
  margin-top: 64px;
  border: 1px solid lightgray;
  border-radius: 4px;
}
.water-profile-overview .create-new-profile h4 {
  margin: 0;
}

