.input-field {
  position: relative;
  display: flex;
  flex-direction: column;


  .input-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    //box-shadow: 0 2px 4px rgba(211,214,223,.7);
    border: 1px solid transparent;

    border-radius: 4px;
  }

  .non-selectable {
    cursor: pointer;
  }

  .title-label {
    margin: 0;
    font-weight: 400;
  }

  input {
    padding: 0 12px;
    width: calc(100% - 24px);
    border: none;
    height: 40px;
  }

  .input-wrapper:hover:not(.disabled) {
    .input-field-icon-wrapper {
      .input-field-icon {
        opacity: 1;
        background: darkblue;
        cursor: pointer;
      }
    }
  }

  .input-field-wrapper {
    display: flex;
    align-items: center;
    border: 1px solid lightgrey;

    .input-field-icon {
      background: lightgrey;
      opacity: 0.4;
      height: 24px;
      width: 24px;
      cursor: pointer;

      position: absolute;
      right: 8px;

      border-radius: 100%;
    }
  }

  .input-wrapper:hover {
    .input-field-wrapper {
      border: 1px solid #7ca2f8;
    }
  }

  .error {
    color: red;
  }

  .option-wrapper {
    min-width: calc(100% - 2px);
    width: auto;

    border: 1px solid black;
    position: absolute;

    top: 100%;

    background: white;
    z-index: 99;
    max-height: 250px;
    overflow-y: auto;
  }
  .option-wrapper.open-upwards {
    bottom: 48px;
    top: auto;
  }

  .drop-option {
    list-style: none;
    font-weight: 400;
    color: black;
    cursor: pointer;
  }

  .drop-option:hover {
    border-bottom: 1px solid black;
    font-weight: 700;
  }

  .selected-wrapper {
    margin-top: 4px;
    display: flex;
    flex-wrap: wrap;

    .selected-item {
      border: 1px solid black;
      border-radius: 15px;
      padding: 5px 15px;
      display: flex;
      align-content: center;
      margin-right: 7px;
      margin-bottom: 5px;

      cursor: pointer;
    }
  }
}
input[type="checkbox"] {
  width: auto;
}
