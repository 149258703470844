.show-less-more-wrapper {
  display: flex;
  align-items: flex-end;
  width: 100%;
  div {
    flex: 1;
  }
  .show-less-more {
    padding-right: 8px;
    cursor: pointer;
  }
}
