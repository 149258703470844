.data-calculator {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  .horizontal {
    display: flex;
    justify-content: center;

    width: 100%;

    gap: 32px;
  }

  .real-time-entries {
    display: flex;
  }
}