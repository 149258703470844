.historic-data-page {
  padding: 0 16px;
}
.historic-data-page .sessions {
  flex-direction: column;
  gap: 16px;
  min-width: 360px;
  width: 100%;
}
.historic-data-page .sessions .session {
  width: 100%;
  padding: 4px 8px;
  border: 1px solid lightgray;
}
.historic-data-page .sessions .session h4 {
  margin: 0;
}
.historic-data-page .create-new-session {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px;
  width: 250px !important;
  margin-top: 64px;
  border: 1px solid lightgray;
  border-radius: 4px;
}
.historic-data-page .create-new-session h4 {
  margin: 0;
}

