.annotation {
  width: 100%;
  .graph-overflow-wrapper {
    overflow-x: auto;
    width: 100%;
  }
  .title-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
@media print {
  .annotation {
    page-break-inside: avoid;
    border-bottom: 1px solid black;

    .graph-overflow-wrapper {
      overflow-x: visible !important;
    }

    .title-wrapper {
      button {
        display: none;
      }
    }
  }
}
