body {
  background: whitesmoke;
}

.summary {
  width: 80%;
  max-width: 800px;
  margin: 0 auto;
  padding: 1.5rem 3rem;
  background: white;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(650px, 1fr));
}
.summary .summary-header {
  display: flex;
  justify-content: space-between;
}
.summary .summary-header > * {
  margin: 0;
}
.summary .details {
  border-collapse: collapse;
  width: 100%;
  margin: 2rem 0;
}
.summary .details th {
  text-align: left;
}
.summary .graphs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.summary .parameter-wrapper {
  margin: 32px 0;
}
.summary .parameter-wrapper h2, .summary .parameter-wrapper h4 {
  margin: 0;
}
.summary .parameter-wrapper .horizontal {
  display: flex;
  gap: 16px;
}

@media print {
  .session-header {
    display: none !important;
  }
  .header > .inline-menu {
    display: none !important;
  }
  .header .header-title {
    flex-direction: row-reverse;
  }
  .header .header-title .logo {
    display: none !important;
  }
  .summary {
    margin: 0 !important;
  }
  .summary-header {
    flex-wrap: wrap;
  }
  .summary-header h2 {
    font-size: 2em !important;
  }
  .sortable-table, .table, tbody {
    page-break-inside: auto;
  }
  .map-video-wrapper, canvas {
    page-break-inside: avoid !important;
  }
}

